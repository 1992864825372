<template>
  <div>
    <div
      v-for="(option, index) in options"
      :key="option.id"
      class="flex items-center"
    >
      <div class="pt-1 pr-4 text-gray-400">
        <fa :icon="['far', 'circle']" fixed-width />
      </div>
      <div>
        <sqr-input-text
          :value="option.label"
          @change="optionChange(index, $event)"
        />
      </div>
      <div class="pt-1 px-2">
        <sqr-button icon="trash" @click="optionRemove(index)" />
      </div>
    </div>
    <div class="mt-3">
      <sqr-button
        icon="plus"
        label="Add an option"
        color="blue"
        light
        @click="optionAdd()"
      />
    </div>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
import SqrInputText from '@/sqrd-ui/SqrInputText';

export default {
  name: 'RadioEdit',
  components: { SqrInputText },
  props: {
    question: { type: Object, required: true }
  },
  computed: {
    options() {
      return (this.question && this.question.options) || [];
    }
  },
  methods: {
    optionAdd() {
      const before = this.options;
      const num = before.length + 1;
      const id = nanoid(5);
      const options = [...before, { id, label: `Option ${num}` }];
      const question = { ...this.question, options };
      this.$emit('change', question);
    },
    optionChange(index, label) {
      let options = [...this.options];
      const before = options[index];
      const option = { ...before, label };
      options.splice(index, 1, option);
      const question = { ...this.question, options };
      this.$emit('change', question);
    },
    optionRemove(index) {
      let options = [...this.options];
      options.splice(index, 1);
      const question = { ...this.question, options };
      this.$emit('change', question);
    }
  }
};
</script>
